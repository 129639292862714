import styles from './SplashPage.module.css';
import { GameCard } from './GameCard';
import { ENABLED_GAMES } from '../constants';

export const SplashPage = () => {
  return (<div className={styles.splashWrapper}>
    <h1>Cooknick Dot Com 𐂂 Games</h1>
    <div>
      {ENABLED_GAMES.length > 0
        ? <div className={styles.gameList}>
          {ENABLED_GAMES?.map(game => <GameCard game={game} />)}
        </div>
        : <div className={styles.noGames}> No Games Available Yet</div>}
    </div>
  </div>)
}